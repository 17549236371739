import styled from '@emotion/styled'
import { FormControl } from 'driverama-core/components/formControl/FormControl'

export const SInputWrapper = styled.div`
  width: 100%;
`

export const SFormControl = styled(FormControl)`
  width: 100%;
`
