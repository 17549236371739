import {
  components,
  operations
} from 'driverama-core/api/driverama/generated/customers'
import { HTTPMethod } from 'driverama-core/constants/rest'
import { UseQueryOptions, useQuery } from 'react-query'
import { apiAuthFetcher } from 'utils/authFetcher'
import { URLS } from '../../../constants/api'
import { QUERY_KEYS } from '../../../constants/queryKeys'

export type Customer = operations['searchCustomer']['responses']['200']['content']['application/com.driverama-v1+json']['content'][number]
export type CustomerSearchResponse = operations['searchCustomer']['responses']['200']['content']['application/com.driverama-v1+json']
type QueryBody = Pick<
  components['schemas']['CustomerFilter'],
  'ids' | 'email' | 'fullText'
>
type QueryOpts = UseQueryOptions<unknown, unknown, CustomerSearchResponse>

export async function fetchCustomersSearch(body: QueryBody) {
  const res = await apiAuthFetcher<CustomerSearchResponse>(
    URLS.customersSearch,
    { method: HTTPMethod.POST, body: { filter: body } }
  )

  return res.json
}

export function useCustomersSearchQuery(body: QueryBody, opts?: QueryOpts) {
  return useQuery(
    QUERY_KEYS.customersSearch(body),
    async () => fetchCustomersSearch(body),
    opts
  )
}

export function getCustomer(
  customers: components['schemas']['CustomerSearchResponse'][],
  customerId: string
) {
  return customers.find(x => x.id === customerId)
}

export function getCustomerByEmail(
  customers: components['schemas']['CustomerSearchResponse'][],
  email: string
) {
  return customers.find(x => x.email === email)
}

export async function findCustomerByEmail(email: string) {
  const customers = await fetchCustomersSearch({
    email,
    ids: []
  })

  if (customers) {
    return getCustomerByEmail(customers.content, email)
  }

  return null
}
